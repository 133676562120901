<template>
    <div >
  <v-app >
  
    <dashboard-core-app-bar />

    <!-- <dashboard-core-drawer /> -->

    <dashboard-core-view  />
  
    <!-- <dashboard-core-settings /> -->
  </v-app>
</div>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: { 
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
  created() {
    if (this.$i18n.locale == 'ar') {
      this.$vuetify.rtl = true
    } else {
      this.$vuetify.rtl = false
    }

  }
};
</script>
<style >
/* .application_background {
  background: #6886a7 !important;
} */
</style>